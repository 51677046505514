<script>
import { ref } from "vue";
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import { mapActions } from "vuex";
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';
import moment from 'moment'
import useVuelidate from "@vuelidate/core";
import gestioneTeamDiLavoro from "@/components/widgets/worksites/gestioneTeamDiLavoro";
import gestioneMezziCantiere from "@/components/widgets/worksites/gestioneMezziCantiere";
import gestioneAttrezzaturaCantiere from "@/components/widgets/worksites/gestioneAttrezzaturaCantiere";
import gestioneProdottiCantiere from "@/components/widgets/worksites/gestioneProdottiCantiere";

import pubblicazioneRapporto from "@/components/widgets/worksites/pubblicazioneRapporto";

//import documentManager from "@/components/widgets/documentManager";
import documentManagerUT from "@/components/widgets/projects/documentManagerUT";


export default {
  page: {
    title: "Dettaglio Rapporto d'intervento",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    
    let tk = 'Bearer '+localStorage.getItem('tk')

    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      showProducts:false,
      showAttached:false,
      showMainSystems:false,
      showMainSurvey:false,
      showMainAdmin:false,
      showMainDocuments:false,
      showAmmProgect:false,
      showTechProgect:false,
      showAdvancedProgect:false,
      title: "Dettaglio Rapporto d'intervento",
      data:{

      },
      minDate:moment().format('YYYY-MM-DD'),
      maxDate:new Date().getFullYear().toString() + '-12-31',
      minDateEnd:moment().format('YYYY-MM-DD'),
      maxDateEnd:new Date().getFullYear().toString() + '-12-31',
      worksite:{},
      task:{},
      bucket:"",
      customer:{},
      items: [
        {
          text: "Elenco rapporti d'intervento",
          href: "/intervention-reports/list-view",
        },
        {
          text: "Rapporto d'intervento",
          active: true,
        },
      ],
      
    };
  },
  mounted() {
   this.getItem()

   if (this.$route.name=='support-detail-task') {
    this.title = 'Dettaglio Attività di Assistenza'
    this.items = [
        {
          text: "Elenco Installazioni",
          href: "/support/list-view",
        },
        {
          text: "Dettaglio assistenza",
          href: `/support/detail/${this.$route.params.id}`,
        },
        {
          text: "Dettaglio attività",
          active: true,
        },
      ];
   }


  },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
            Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                this.$router.push('/')         
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    convertDateToLocal(date){
      moment.locale('it')
      return moment(date).format('LLLL')
    },
    convertTmpToDate(val){
      return moment.unix(val).format('LLLL')
    },
    createBucket(data){
      let name
      let code 
      if (data.ragSoc){
        name = data.ragSoc.replaceAll(' ','_')
      }
      if (data.codiceFiscale.length==16){
        code = data.codiceFiscale
      }
      if (data.partitaIva.length==11){
        code = data.partitaIva
      }
      return `${code}_${name}`
    },
    updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.data.startStrLavori
          this.data.endStrLavori = this.data.startStrLavori
        }
        if (type=='end'){
          this.minDateEnd = this.data.startStrLavori
        }
        this.update()
     },
    checkObjEl(obj){
      if (obj){
        return Object.keys(obj).length
      } else {
        return false
      }
    },
    getItem() {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}intervention-reports/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data = response.data[0]
            if(this.data.task) {
              this.worksite = response.data[0].task[0]
            }
            if(this.data.task) {
              this.task = response.data[0].worksite[0] 
            }
            if (this.data.project){
              if (this.data.project[0].customerDetails){
                this.bucket = this.createBucket(this.data.project[0].customerDetails) +'/documentazione-interventi/'
              }
            }     
          }        
        })
    },
    updateProgress(){
      if(this.data.progress>100){
        this.data.progress = 100
      }
      this.update()
    },
    update(){
     
     this.axiosInterceptor()
     let dataInt = {}
     dataInt.dateStringLavori = this.data.startStrLavori 
     dataInt.dateTmpLavori  = moment(this.data.startStrLavori ).unix()
     dataInt.startStrLavori = this.data.startStrLavori
     dataInt.startLavori  = moment(this.data.startStrLavori).valueOf()
     dataInt.start_tmpLavori  = moment(this.data.startStrLavori).unix()
     dataInt.endStrLavori = this.data.endStrLavori
     dataInt.endLavori  = moment(this.data.endStrLavori).valueOf()
     dataInt.end_tmpLavori  = moment(this.data.endStrLavori).unix()
     dataInt.indicazioniCantiere = this.data.indicazioniCantiere
     dataInt.lavorazioniEffettuate = this.data.lavorazioniEffettuate
     if (this.data.progress) {
      dataInt.progress = this.data.progress
     }

     axios.put(`${this.UrlServer}intervention-reports/${this.data._id}`, dataInt, {headers: { authorization:this.tk}})
     .then(() => {
         //this.getItem()
         //Swal.fire( this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
     })
   },    
    updateTeamParent() {
     
     this.getItem()
   },
   getCustomerData(){
    if (this.data){
      if(this.data.project.length>0) {
        if (this.data.project[0].customerID){
          this.axiosInterceptor()
          axios.get(`${serverConfig.EP}customers/${this.data.project[0].customerID}`, 
              { 
                headers: { 'authorization':this.tk},
              },

            ).then(response => {
                if (response.data){
                  this.customer = response.data.customerData
                }
            });
        }
      }
    }
   }
  },
  beforeMount() {
    this.axiosInterceptor()
    axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk},
          params: { 
            page: this.$router.currentRoute._rawValue.name,
          },
        },

      ).then(response => {
          if (response.data){
            if (response.data.user){
              if (response.data.user.roles_permissions.layout) {
                this.changeLayoutType({layoutType:response.data.user.roles_permissions.layout})
              }
            }
          }
      });
    },
  components: {   
    Layout,
    PageHeader,
    gestioneTeamDiLavoro,
    gestioneMezziCantiere,
    gestioneProdottiCantiere,
    gestioneAttrezzaturaCantiere,
    documentManagerUT,
    pubblicazioneRapporto,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card background-btn-primary">
        <div class="text-center"><h3>Rapporto d'intervento</h3></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">    
          <template v-if="data">

              <div class="row mb-50 mt-20" v-if="data.project">
                 
                <div class="col-lg-12">
                 
                  <template v-if="data.project.length>0">
                 
                  <b-accordion class="custom-accordionwithicon-plus" id="accordionWithplusicon">
                      <template v-if="data.project[0].customerDetails">
                              <b-accordion-item :title="'Cliente: '+data.project[0].customerDetails.ragSoc" unvisible>
                              <div class="table-responsive">
                                  <table class="table mb-0">
                                      <thead>
                                          <tr>
                                              <th scope="col">Nominativo</th>
                                              <th scope="col">Indirizzo</th>
                                              <th scope="col">Città</th>
                                              <th scope="col">Telefono</th>
                                              <th scope="col">Email</th>
                                              <th scope="col">#</th>
                                          </tr>
                                      </thead>
                                      <tbody v-if="data.project.length>0">
                                          <tr>
                                              <td>{{ data.project[0].customerDetails.ragSoc }}</td>
                                              <td>{{ data.project[0].customerDetails.indirizzo }}</td>
                                              <td>{{ data.project[0].customerDetails.citta }}</td>
                                              <td>{{ data.project[0].customerDetails.telefono }}</td>
                                              <td><a :href="'mailto:'+data.project[0].customerDetails.email">{{ data.project[0].customerDetails.email }}</a></td>
                                              <td><button class="btn btn-info" title="Vedi anagrafica completa">Vedi</button></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </b-accordion-item>
                      </template>
                      <template v-if="data.worksite && data.worksite.length>0">
                        <b-accordion-item :title="'Localizzazione Cantiere: '+data.worksite[0].address +' - '+data.worksite[0].city" unvisible>
                              <div class="row" v-if="worksite">
                                  <div class="col-lg-12">
                                  <div class="card-body mb-50">

                                      <div class="row mb-50 pl-10">
                                          <div class="col-md-12 col-lg-4">
                                          <label for="code-field" class="form-label"><strong>Indirizzo</strong></label>
                                              <div class="input-group mb-1">
                                                  {{ data.worksite[0].address }}
                                              </div>
                                          </div>
                                          <div class="col-md-12 col-lg-3">
                                          <label for="code-field" class="form-label"><strong>Città</strong></label>
                                          <div class="input-group mb-1">
                                              {{ data.worksite[0].city }}
                                          </div>
                                          </div>
                                          <div class="col-md-12 col-lg-2">
                                          <label for="code-field" class="form-label"><strong>C.A.P</strong></label>
                                          <div class="input-group mb-1" >
                                              {{ data.worksite[0].zipCode }}
                                          </div>
                                          </div>
                                      </div>
                                      <div class="row mb-50 mt-20 pl-10">
                                      <div class="col-md-12 col-lg-3">
                                          <label for="code-field" class="form-label"><strong>Inizio lavori</strong></label>
                                          <div class="input-group mb-1" v-if="data.worksite[0].startStr">
                                              <template v-if="data.worksite[0].startStr!=''">
                                                {{ convertDateToLocal(data.worksite[0].startStr) }}
                                              </template>
                                          </div>
                                      </div>
                                      <div class="col-md-12 col-lg-3">
                                          <label for="code-field" class="form-label"><strong>Fine lavori</strong></label>
                                          <div class="input-group mb-1" v-if="data.worksite[0].endStr">
                                              <template v-if=" data.worksite[0].endStr !=''">
                                                {{ convertDateToLocal( data.worksite[0].endStr ) }}
                                              </template>
                                          </div>
                                      </div>
                                      </div>
                                  
                                  </div>
                                  </div>
                              </div>
                        </b-accordion-item>
                      </template>

                      
                      </b-accordion>
                   
                  </template>
                </div>
                
              </div>
        <template v-if="data.task">
          <template v-if="data.task.length>0">
            <div class="mt-50">
              <div class="row background-btn-grey-1  mb-50 mt-50 pl-10  pt-10" >
                    <div class="col-md-12 col-lg-12  text-center">
                      <h4>Indicazioni dal coordinatore</h4>
                      
                    </div>
              </div>
              <div class="row background-btn-grey-1  mb-50 mt-50 pl-10  pt-10" v>
                    <div class="col-md-12 col-lg-3 ">
                        <label for="code-field" class="form-label"><strong>Inizio attività</strong></label>
                            <div class="input-group mb-1 c-green" v-if="data.task[0].startStr" >
                            <template v-if="data.task[0].startStr!=''">
                              {{ convertDateToLocal(data.task[0].startStr) }}
                            </template>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <label for="code-field" class="form-label"><strong>Fine attività</strong></label>
                                <div class="input-group mb-1 c-red" v-if="data.task[0].endStr">
                                  <template v-if="data.task[0].endStr!=''">
                                    {{ convertDateToLocal(data.task[0].endStr) }}
                                  </template>
                                </div>
                        </div>
                </div>
                <div class="row background-btn-grey-1  pl-10 pt-10" >
                    <div class="col-md-12 col-lg-4">
                        <label for="code-field" class="form-label"><strong>Denominazione attività (opzionale)</strong></label>
                        <div class="input-group mb-1">
                          {{ data.task[0].name }}
                        </div>
                    </div>
                </div>
                <div class="row background-btn-grey-1 pl-10 pt-10 pb-20" >
                    <div class="col-md-12 col-lg-6">
                        <label for="code-field" class="form-label"><strong>Descrizione generale (opzionale)</strong></label>
                        <div class="input-group mb-1">
                          {{ data.task[0].details }}
                        </div>
                    </div>
                </div>
            </div>
          </template>
        </template>
          <div class="mt-50">
            <div class="row background-btn-grey-1  mb-50 mt-50 pl-10  pt-10" >
                  <div class="col-md-12 col-lg-12  text-center">
                      <h4>Scheda tecnica intervento</h4>
                  </div>
            </div>
            <div class="row background-btn-grey-1 pl-10 pt-10 pb-20" >
                  <div class="col-md-12 col-lg-12">
                      <label for="code-field" class="form-label"><strong>Indicazioni di cantiere</strong></label>
                      <div class="input-group mb-1">
                          <textarea class="form-control" placeholder="Indicazioni generiche di cantiere" v-model="data.indicazioniCantiere" @blur="update"></textarea>
                      </div>
                  </div>
              </div>
            <div class="row background-btn-grey-1  mb-50 mt-50 pl-10  pt-10" >
                  <div class="col-md-12 col-lg-3 ">
                      <label for="code-field" class="form-label"><strong>Inizio lavorazioni</strong></label>
                          <div class="input-group mb-1">
                            
                              <input type="datetime-local" class="form-control"  :min="minDate" :max="maxDate" @change="updateDate('start')"  v-model="data.startStrLavori" />
                          </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                          <label for="code-field" class="form-label"><strong>Fine lavorazioni</strong></label>
                              <div class="input-group mb-1">
                                  <input type="datetime-local" class="form-control" :min="minDateEnd" :max="maxDateEnd"  @change="updateDate('end')"  v-model="data.endStrLavori" />
                              </div>
                      </div>
              </div>
              <div class="row background-btn-grey-1 pl-10 pt-10 pb-20" >
                  <div class="col-md-12 col-lg-12">
                      <label for="code-field" class="form-label"><strong>Descrizione delle lavorazioni effettuate</strong></label>
                      <div class="input-group mb-1">
                          <textarea class="form-control" placeholder="Descrizione delle lavorazioni effettuate" v-model="data.lavorazioniEffettuate" @blur="update"></textarea>
                      </div>
                  </div>
              </div>
              <div class="row  pl-10 pt-10 pb-20" >
                  <div class="col-md-12 col-lg-3">
                    <label for="code-field" class="form-label pt-2"><strong>Progresso lavorazione (%)</strong></label>
                  </div>
                  <div class="col-lg-1 col-md-12 text-left">
                  </div>
              </div>
              <div class="row  pl-10 pb-20" >
                  <div class="col-lg-1 col-md-12 text-left">
                    <input type="number" class="form-control" v-model="data.progress" min="1" max="100" @blur="updateProgress" />
                  </div>
              </div>
              <div class="row background-btn-grey-1 pl-10 pt-10 pb-20" >
                  <div class="col-md-12 col-lg-12">
                    <div
                    class="
                      progress
                      animated-progess
                      custom-progress
                      progress-label"
                      >
                        <div
                          class="progress-bar bg-info progress-xl"
                          role="progressbar"
                          :style="'width: '+data.progress+'%'"
                          :aria-valuenow="data.progress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div class="label">{{data.progress}}%</div>
                        </div>
                      </div>

                  </div>
              </div>
          </div>
            
      </template>
          <div class="row" v-if="data.task"> 
            <div class="col-md-12 col-lg-12 mt-50 border-btn-grey-2" v-if="data.status=='init'">
              <template v-if="data.task.length>0">
                
                  <template v-if="checkObjEl( data.task[0].assignedTo).length==0">
                    <b-alert show variant="danger" role="alert">
                      <strong>Attenzione:</strong> E' indispensabile assegnare la lavorazione ad un <strong>Team di lavoro</strong>, e ad un <strong>'team leader'</strong> o  <strong>'caposquadra'</strong> all'interno di questo.
                    </b-alert>
                  </template>
              </template>
            </div>
          </div>
            <div class="row mt-25 mb-25">
              <div class="col-md-12 col-lg-12 background-btn-grey">
                <div class="card-header align-items-center d-flex">
                  
                  <h4 class="card-title mb-0 flex-grow-1">               
                      Registra materiale e prodotti utilizzati
                  </h4>
                  <div class="flex-shrink-0">
                    <div
                      class="form-check form-switch form-switch-right form-switch-md"
                    >
                      <label for="FormSelectDefault" class="form-label text-muted"
                        >Mostra</label
                      >
                      <input
                        class="form-check-input code-switcher"
                        type="checkbox"
                        id="FormSelectDefault"
                        v-model="showProducts"
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="row" v-if="showProducts">
            <div class="col-md-12 col-lg-12  mt-25 mb-10 " >
              <template v-if="data.worksite[0]._id">
                <gestioneProdottiCantiere type="intervention" :projectID="data.project[0]._id" :worksiteID="data.worksite[0]._id" :taskID="data.task[0]._id" :interventionID="data._id" :data="data"  @updateTeam="updateTeamParent" />
              </template>
            </div>
          </div>
          <div class="row mt-25 mb-25">
              <div class="col-md-12 col-lg-12 background-btn-grey">
                <div class="card-header align-items-center d-flex">
                  
                  <h4 class="card-title mb-0 flex-grow-1">               
                      Inserisci allegati e immagini
                  </h4>
                  <div class="flex-shrink-0">
                    <div
                      class="form-check form-switch form-switch-right form-switch-md"
                    >
                      <label for="FormSelectDefault" class="form-label text-muted"
                        >Mostra</label
                      >
                      <input
                        class="form-check-input code-switcher"
                        type="checkbox"
                        id="FormSelectDefault"
                        v-model="showAttached"
                        @click="getCustomerData"
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="row" v-if="showAttached">
           <div class="col-md-12 col-lg-12  mt-25  mb-10 " >
            <template v-if="data">
              <template v-if="data.project.length>0">
                <template v-if="data.project[0].customerID">
                      <template v-if="!customer.nomeFascicolo">
                          <b-alert show variant="danger" class="mb-25">
                            <strong> "Nome Fascicolo" non individuato: </strong> aggiorna l'anagrafica cliente, per favore.
                          </b-alert>
                      </template>
                      <template v-else>
                        <documentManagerUT 
                          :url="`${this.UrlServer}storage/by/project`"
                          :projectID="data.project[0]._id" 
                          :data="data" 
                          :bucket="customer.nomeFascicolo + data.project[0].year.toString() + '/' + data.project[0].systemSubCategory+'/'" 
                          type="doc-task"
                          refDirectory="assistenza"
                          fileType="multiple" 
                          fileAccept="*" 
                          title="Documentazione impianto"
                          v-on:getParent="getParent()"/>

                      </template>               
                </template>
                <template v-else>
                  <b-alert show variant="danger" class="mb-25">
                    <strong> "Nome Fascicolo" non individuato: </strong> non risulta associata alcuna anagrafica cliente al progetto.
                  </b-alert>
                </template>
              </template>
            </template>
            </div>
          </div>
        </div>

        </div>
        <div class="card">
        
        </div>
      
      </div>
     
      <div class="col-lg-4">
       
        <template v-if="data">
          <div class="card">
            <div class="card-header background-btn-grey-1">
              <h5 class="card-title mb-0">Pubblicazione rapporto</h5>
            </div>
            <div class="card-body" v-if="data.worksite">
              <template v-if="data.worksite[0]._id">
                <pubblicazioneRapporto  :projectID="data.project[0]._id" :worksiteID="data.worksite[0]._id" :taskID="data.task[0]._id" :interventionID="data._id" :data="data"  @updateTeam="updateTeamParent" />
              </template>
            </div>
          </div>
          <div class="card">
            <div class="card-header background-btn-grey-1">
              <h5 class="card-title mb-0">Team di lavoro</h5>
            </div>
            <div class="card-body" >
              
              <div class="mb-3" v-if="data.worksite">
                
                <label for="choices-lead-input" class="form-label" v-if="$route.name!='intervention-reports-detail'">Assegna lavorazione:</label
                >
                <template v-if="data.worksite.length>0">
                  <gestioneTeamDiLavoro  type="intervention" :taskID="data.task[0]._id" :interventionID="data._id" :projectID="data.worksite[0].projectID" :worksiteID="data.worksite[0]._id" :data="data.task[0]"  @updateTeam="updateTeamParent" />
                </template>    
              </div>

            </div>
          </div>
          <div class="card">
              <div class="card-header background-btn-grey-1">
              <h5 class="card-title mb-0">Mezzi impegnati</h5>
            </div>
            <div class="card-body">
              <div class="mb-3" v-if="data.worksite">
                <label for="choices-lead-input" class="form-label"
                  >Assegna Mezzo:</label
                >
                <template v-if="data.worksite[0]._id">
                    <gestioneMezziCantiere  type="intervention" :projectID="data.project[0]._id" :worksiteID="data.worksite[0]._id" :taskID="data.task[0]._id" :interventionID="data._id" :data="data"  @updateTeam="updateTeamParent" />
                </template>
              </div>

            </div>
          </div>
          <div class="card">
              <div class="card-header background-btn-grey-1">
              <h5 class="card-title mb-0">Gestione Attrezzatura</h5>
            </div>
            <div class="card-body">
              <div class="mb-3" v-if="data.worksite">
                <label for="choices-lead-input" class="form-label"
                  >Assegna Attrezzatura:</label
                >
                <template v-if="data.worksite[0]._id">
                    <gestioneAttrezzaturaCantiere type="intervention" :projectID="data.project[0]._id" :worksiteID="data.worksite[0]._id" :taskID="data.task[0]._id" :interventionID="data._id" :data="data"  @updateTeam="updateTeamParent" />
                </template>
              </div>

            </div>
          </div>

        </template>
        
      </div>
    
    </div>
  </Layout>
</template>
